import dayjs from 'dayjs';
import { Charger } from './apis/searchAlongRoute';
import { Restaurant } from './apis/findRestaurants';

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_CUSTOM_GOOGLE_API_KEY || 'AIzaSyDPzGNpHOKBwbMz-6oX-TYmUtnGeLUN_Yk';

export enum LoadingState {
  ERROR,
  IDLE,
  LOADING,
  SUCCESS,
}

export enum EVConnectorType {
  CCS = 'EV_CONNECTOR_TYPE_CCS_COMBO_1',
  TESLA = 'EV_CONNECTOR_TYPE_TESLA',
}

export const EVConnectorNames: Record<EVConnectorType, string> = {
  [EVConnectorType.CCS]: 'CCS',
  [EVConnectorType.TESLA]: 'Tesla',
};

export type TimeOfDay = {
  hour: number;
  minute: number;
};

export type MealTimeOptions = {
  arrivalTime: dayjs.Dayjs;
  charger: Charger;
  restaurants: {
    distance: number;
    place: Restaurant;
  }[];
  simplifiedLocation: string;
};

export type RoutingStorage = {
  chargerTypes: EVConnectorType[];
  chargeTime: number;
  meals: TimeOfDay[];
  range: number;
};

export const DEFAULT_STORAGE: RoutingStorage = {
  chargerTypes: [EVConnectorType.CCS, EVConnectorType.TESLA],
  chargeTime: 30,
  meals: [
    {
      hour: 12,
      minute: 0,
    },
    {
      hour: 17,
      minute: 0,
    },
  ],
  range: 240,
};
