import React from 'react';
import LocationSearchField from './LocationSearchField';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Fab,
  FormControlLabel,
  Grid2,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Charger, searchAlongRoute } from './apis/searchAlongRoute';
import { findStopsForMeal, getDistanceBetweenPoints } from './findStopsForMeal';
import dayjs from 'dayjs';
import { LoadingState, MealTimeOptions, TimeOfDay } from './constants';
import TimeOfDayField from './TimeOfDayField';
import { dayjsToTimeOfDay } from './dateUtils';
import { useLocalStorage } from './useLocalStorage';
import Settings from './Settings';
import MealOption from './MealOption';
import { grey } from '@mui/material/colors';
import { getDirections } from './apis/fetchQueries';
import { findRestaurants } from './apis/findRestaurants';
import { Map as MapIcon, Navigation } from '@mui/icons-material';

const Routing: React.FC = () => {
  const theme = useTheme();
  const midOrBigger = useMediaQuery(theme.breakpoints.up('md'));

  const [chargeTime] = useLocalStorage('chargeTime');
  const [chargerTypes] = useLocalStorage('chargerTypes');
  const [meals] = useLocalStorage('meals');
  const [range] = useLocalStorage('range');

  const [planningState, setPlanningState] = React.useState(LoadingState.IDLE);
  const [isUsingCurrentLocation, setIsUsingCurrentLocation] = React.useState(false);
  const [canUseCurrentLocation, setCanUseCurrentLocation] = React.useState(false);
  const [location, setLocation] = React.useState<google.maps.LatLng | null>(null);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [directionsRenderer] = React.useState(new google.maps.DirectionsRenderer());
  const [start, setStart] = React.useState<string | google.maps.LatLng | null>(null);
  const [end, setEnd] = React.useState<string | null>(null);
  const [mealOptions, setMealOptions] = React.useState<MealTimeOptions[]>([]);
  const [startTime, setStartTime] = React.useState<TimeOfDay | null>({ hour: 8, minute: 0 });
  const [markers, setMarkers] = React.useState<google.maps.marker.AdvancedMarkerElement[]>([]);

  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    (async () => {
      const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        zoom: 6,
        mapId: 'ROUTE_MAP',
      });
      setMap(map);
      directionsRenderer.setMap(map);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const initialLocation = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude,
            );
            map.setCenter(initialLocation);
            setLocation(initialLocation);
            setCanUseCurrentLocation(true);
          },
          () => {
            setCanUseCurrentLocation(false);
          },
        );
      }
    })();
  }, []);

  const handleSearch = async () => {
    if (!start || !end) {
      return;
    }

    try {
      setPlanningState(LoadingState.LOADING);
      const directions = await getDirections({
        origin:
          typeof start === 'string'
            ? {
                placeId: start,
              }
            : {
                location: start,
              },
        destination: {
          placeId: end,
        },
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      });
      directionsRenderer.setDirections(directions);

      const chargersAlongRoute = await searchAlongRoute(
        directions.routes[0].overview_polyline,
        chargerTypes,
        directions.routes[0].legs[0].duration?.value || 0,
      );
      chargersAlongRoute.forEach((charger) => {
        charger.evChargeOptions.connectorAggregation =
          charger.evChargeOptions.connectorAggregation.filter((connector) =>
            chargerTypes.includes(connector.type),
          );
      });
      const mealTimeStops = findStopsForMeal({
        chargers: chargersAlongRoute,
        chargeTime,
        mealTimes: meals,
        range,
        startTime: startTime || dayjsToTimeOfDay(dayjs()),
      });

      markers.forEach((marker) => {
        marker.map = null;
      });

      const mealOptionPromises: Promise<any>[] = [];
      setMealOptions([]);
      mealTimeStops.forEach((stop) => {
        if (stop.charger.location) {
          mealOptionPromises.push(
            findRestaurants(stop.charger).then((restaurants) => {
              if (restaurants.length) {
                const city = stop.charger.addressComponents.find((comp) =>
                  comp.types.includes('locality'),
                );
                const state = stop.charger.addressComponents.find((comp) =>
                  comp.types.includes('administrative_area_level_1'),
                );
                setMealOptions((current) => [
                  ...current,
                  {
                    arrivalTime: stop.arrivalTime,
                    charger: stop.charger,
                    restaurants: restaurants.map((restaurant) => ({
                      distance: getDistanceBetweenPoints(
                        {
                          latitude: restaurant.location.latitude,
                          longitude: restaurant.location.longitude,
                        },
                        stop.charger.location,
                      ),
                      place: restaurant,
                    })),
                    simplifiedLocation: [city?.longText, state?.shortText]
                      .filter(Boolean)
                      .join(', '),
                  },
                ]);
                const marker = new google.maps.marker.AdvancedMarkerElement({
                  map,
                  position: {
                    lat: stop.charger.location.latitude,
                    lng: stop.charger.location.longitude,
                  },
                });
                setMarkers((current) => [...current, marker]);
              }
            }),
          );
        }
      });

      if (mealTimeStops.length === 0) {
        setPlanningState(LoadingState.SUCCESS);
      } else {
        await Promise.allSettled(mealOptionPromises);
        setPlanningState(LoadingState.SUCCESS);
      }
    } catch (e) {
      setPlanningState(LoadingState.ERROR);
    }
  };

  React.useEffect(() => {
    if (isUsingCurrentLocation) {
      setStart(location);
      setStartTime(dayjsToTimeOfDay(dayjs()));
    }
  }, [isUsingCurrentLocation, location]);

  const handleSelectCharger = (charger: Charger) => {
    if (map) {
      map.setCenter({
        lat: charger.location.latitude,
        lng: charger.location.longitude,
      });
      map.setZoom(17);
      mapRef.current?.scrollIntoView();
    }
  };

  const handleJumpToMap = () => {
    mapRef.current?.scrollIntoView();
  };

  return (
    <Box display="relative">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          {canUseCurrentLocation && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(_event, checked) => setIsUsingCurrentLocation(checked)}
                  value={isUsingCurrentLocation}
                />
              }
              label="Use current location and time"
            />
          )}
          <Collapse in={!isUsingCurrentLocation}>
            <Box display="flex" gap={2} mt={canUseCurrentLocation ? 1 : 0}>
              <Box maxWidth={800} width="100%">
                <LocationSearchField
                  label="Start"
                  location={location}
                  onSelect={setStart}
                  persistedValue={start}
                />
              </Box>
              <Box>
                <TimeOfDayField
                  label="Departure time (optional)"
                  setValue={setStartTime}
                  value={startTime}
                />
              </Box>
            </Box>
          </Collapse>
        </Grid2>
        <Grid2 size={12}>
          <Box maxWidth={800}>
            <LocationSearchField
              label="End"
              location={location}
              onSelect={setEnd}
              persistedValue={end}
            />
          </Box>
        </Grid2>
        <Grid2 size={12}>
          <Button
            disabled={!start || !end || planningState === LoadingState.LOADING}
            onClick={handleSearch}
            startIcon={
              planningState === LoadingState.LOADING ? (
                <CircularProgress size={14} />
              ) : (
                <Navigation sx={{ fontSize: 'small' }} />
              )
            }
            sx={{ mr: 2 }}
            variant="contained"
          >
            Plan route
          </Button>
          <Settings />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, xl: 3 }}>
          {mealOptions
            .sort((a, b) => a.arrivalTime.diff(b.arrivalTime))
            .map((option, index) => (
              <Box key={option.charger.id} mt={index > 0 ? 2 : 0}>
                <MealOption onSelectCharger={handleSelectCharger} option={option} />
              </Box>
            ))}
          {planningState === LoadingState.SUCCESS && !mealOptions.length && (
            <Box
              alignItems="center"
              bgcolor={grey[200]}
              borderRadius="8px"
              display="flex"
              justifyContent="center"
              minHeight={64}
              p={2}
            >
              <Typography>No meal stops found</Typography>
            </Box>
          )}
          {(planningState === LoadingState.IDLE || planningState === LoadingState.LOADING) &&
            !mealOptions.length && (
              <Box
                alignItems="center"
                bgcolor={grey[200]}
                borderRadius="8px"
                display="flex"
                justifyContent="center"
                minHeight={64}
                p={2}
              >
                <Typography>Plan route to view restaurant options</Typography>
              </Box>
            )}
        </Grid2>
        <Grid2 size={{ xs: 12, md: 8, xl: 9 }}>
          <div id="map" ref={mapRef} style={{ height: '600px', width: '100%' }} />
        </Grid2>
      </Grid2>
      {!midOrBigger && mealOptions.length > 0 && (
        <Box bottom={theme.spacing(2)} left={theme.spacing(2)} position="fixed">
          <Fab color="primary" onClick={handleJumpToMap}>
            <MapIcon />
          </Fab>
        </Box>
      )}
    </Box>
  );
};

export default Routing;
