import React from 'react';
import Routing from './Routing';
import { GOOGLE_MAPS_API_KEY, LoadingState } from './constants';
import { Loader } from '@googlemaps/js-api-loader';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './apis/queryClient';

function App() {
  const [loadingState, setLoadingState] = React.useState(LoadingState.IDLE);

  React.useEffect(() => {
    (async () => {
      try {
        setLoadingState(LoadingState.LOADING);

        const googleMapsLoader = new Loader({
          apiKey: GOOGLE_MAPS_API_KEY,
        });

        await googleMapsLoader.importLibrary('routes');
        await googleMapsLoader.importLibrary('maps');
        await googleMapsLoader.importLibrary('places');
        await googleMapsLoader.importLibrary('marker');

        setLoadingState(LoadingState.SUCCESS);
      } catch (e) {
        setLoadingState(LoadingState.ERROR);
      }
    })();
  }, []);

  if (loadingState === LoadingState.ERROR) {
    return <>Error loading Google Maps</>;
  }

  if (loadingState === LoadingState.SUCCESS) {
    return (
      <QueryClientProvider client={queryClient}>
        <Routing />
      </QueryClientProvider>
    );
  }

  return <>Loading...</>;
}

export default App;
