import { GOOGLE_MAPS_API_KEY } from '../constants';
import queryClient, { QueryKeys } from './queryClient';

export const getDirections = (request: google.maps.DirectionsRequest) => {
  const directionsService = new google.maps.DirectionsService();
  return queryClient.fetchQuery({
    queryKey: [QueryKeys.GET_DIRECTIONS, request],
    queryFn: () => directionsService.route(request),
  });
};

export type SearchTextResponse = {
  nextPageToken?: string;
  places: any[];
  routingSummaries?: {
    legs: {
      duration: string;
      distanceMeters: number;
    }[];
    directionsUri: string;
  }[];
};
export const searchText = ({
  body,
  fields,
}: {
  body: any;
  fields: string[];
}): Promise<SearchTextResponse> => {
  return queryClient.fetchQuery({
    queryKey: [QueryKeys.SEARCH_TEXT, body, fields],
    queryFn: async () => {
      const response = await fetch('https://places.googleapis.com/v1/places:searchText', {
        method: 'POST',
        headers: {
          'X-Goog-Api-Key': GOOGLE_MAPS_API_KEY,
          'X-Goog-FieldMask': [...fields, 'nextPageToken'].join(','),
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        return response.json();
      }

      throw response;
    },
  });
};

export type SearchNearbyResponse = {
  nextPageToken?: string;
  places: any[];
};
export const searchNearby = ({
  body,
  fields,
}: {
  body: any;
  fields: string[];
}): Promise<SearchNearbyResponse> => {
  return queryClient.fetchQuery({
    queryKey: [QueryKeys.SEARCH_NEARBY, body, fields],
    queryFn: async () => {
      const response = await fetch('https://places.googleapis.com/v1/places:searchNearby', {
        method: 'POST',
        headers: {
          'X-Goog-Api-Key': GOOGLE_MAPS_API_KEY,
          'X-Goog-FieldMask': fields.join(','),
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        return response.json();
      }

      throw response;
    },
  });
};
