import React from 'react';
import { EVConnectorNames, MealTimeOptions } from './constants';
import { Box, Typography, IconButton, Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import { displayArrival } from './dateUtils';
import { Charger } from './apis/searchAlongRoute';
import { OpenInNew, Place } from '@mui/icons-material';

type Props = {
  onSelectCharger: (charger: Charger) => void;
  option: MealTimeOptions;
};

const MealOption: React.FC<Props> = ({ onSelectCharger, option }) => {
  return (
    <Box bgcolor={grey[200]} borderRadius="8px" p={2}>
      <Box alignItems="center" display="flex" gap={1}>
        <Typography variant="h5">
          {option.simplifiedLocation} ({displayArrival(option.arrivalTime)})
        </Typography>
        <IconButton color="primary" onClick={() => onSelectCharger(option.charger)}>
          <Place />
        </IconButton>
        <IconButton color="primary" href={option.charger.googleMapsLinks.placeUri} target="_blank">
          <OpenInNew />
        </IconButton>
      </Box>
      <Typography>
        <strong>{option.charger.displayName.text}</strong>
      </Typography>
      {option.charger.evChargeOptions.connectorAggregation
        .sort((a, b) => b.maxChargeRateKw - a.maxChargeRateKw)
        .map((connector, index) => (
          <Typography key={index}>
            {connector.count} {EVConnectorNames[connector.type]} chargers:{' '}
            <strong>{connector.maxChargeRateKw}kw</strong>
          </Typography>
        ))}
      {option.restaurants
        .sort((a, b) => a.distance - b.distance)
        .map((restaurant, index) => (
          <Box key={restaurant.place.id} ml={2} mt={index === 0 ? 1 : 0}>
            <Link href={restaurant.place.googleMapsLinks.placeUri} target="_blank" variant="body1">
              {restaurant.place.displayName.text} ({restaurant.distance}ft)
            </Link>
          </Box>
        ))}
    </Box>
  );
};

export default MealOption;
