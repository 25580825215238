import React from 'react';
import { DEFAULT_STORAGE, RoutingStorage } from './constants';

export const useLocalStorage = <KEY extends keyof RoutingStorage>(
  key: KEY,
): [RoutingStorage[KEY], (value: RoutingStorage[KEY]) => void] => {
  const readFromStorage = (): RoutingStorage[KEY] => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : DEFAULT_STORAGE[key];
  };

  const [value, setValue] = React.useState(readFromStorage());

  React.useEffect(() => {
    const eventListener = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(readFromStorage());
      }
    };
    window.addEventListener('storage', eventListener);
    return () => {
      window.removeEventListener('storage', eventListener);
    };
  }, []);

  const handleSetValue = (newValue: RoutingStorage[KEY]) => {
    const oldValue = window.localStorage.getItem(key);
    window.localStorage.setItem(key, JSON.stringify(newValue));
    window.dispatchEvent(
      new StorageEvent('storage', {
        key,
        oldValue,
        newValue: JSON.stringify(newValue),
      }),
    );
  };

  return [value, handleSetValue];
};
