import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

export default queryClient;

export enum QueryKeys {
  GET_DIRECTIONS = 'GET_DIRECTIONS',
  SEARCH_NEARBY = 'SEARCH_NEARBY',
  SEARCH_TEXT = 'SEARCH_TEXT',
}
