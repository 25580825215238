import { EVConnectorType } from '../constants';
import { searchText, SearchTextResponse } from './fetchQueries';

export type Charger = {
  addressComponents: {
    longText: string;
    shortText: string;
    types: string[];
  }[];
  displayName: {
    text: string;
  };
  distanceMeters: number;
  evChargeOptions: {
    connectorCount: number;
    connectorAggregation: {
      count: number;
      maxChargeRateKw: number;
      type: EVConnectorType;
    }[];
  };
  googleMapsLinks: {
    directionsUri: string;
    photosUri: string;
    placeUri: string;
    reviewsUri: string;
    writeAReviewUri: string;
  };
  id: string;
  location: {
    latitude: number;
    longitude: number;
  };
  minutesOffRoute: number;
  secondsToCharger: number;
};

export const searchAlongRoute = async (
  polyline: string,
  connectorTypes: EVConnectorType[],
  routeTimeInSeconds: number,
): Promise<Charger[]> => {
  const chargers: Charger[] = [];
  let hasMadeRequest = false;
  let nextPageToken: string | undefined = undefined;

  while (!hasMadeRequest || nextPageToken) {
    hasMadeRequest = true;
    let response: SearchTextResponse = await searchText({
      body: {
        evOptions: {
          connectorTypes,
          minimumChargingRateKw: 100,
        },
        textQuery: 'charging station',
        searchAlongRouteParameters: {
          polyline: {
            encodedPolyline: polyline,
          },
        },
        pageToken: nextPageToken,
      },
      fields: [
        'places.addressComponents',
        'places.id',
        'places.location',
        'places.evChargeOptions',
        'places.displayName',
        'places.googleMapsLinks',
        'routingSummaries',
      ],
    });
    nextPageToken = response.nextPageToken;
    response.places.forEach((place, index) => {
      const routeSummary = response.routingSummaries?.[index];
      if (routeSummary) {
        const durationToCharger = Number(
          routeSummary.legs[0].duration.substring(0, routeSummary.legs[0].duration.length - 1),
        );
        const durationFromCharger = Number(
          routeSummary.legs[1].duration.substring(0, routeSummary.legs[1].duration.length - 1),
        );
        const totalTimeWithCharger = durationToCharger + durationFromCharger;
        chargers.push({
          ...place,
          distanceMeters: routeSummary.legs[0].distanceMeters,
          minutesOffRoute: Math.round((totalTimeWithCharger - routeTimeInSeconds) / 60 / 2),
          secondsToCharger: durationToCharger,
        });
      }
    });
  }

  return chargers;
};
