import dayjs from 'dayjs';
import { TimeOfDay } from './constants';

export const dayjsToTimeOfDay = (time: dayjs.Dayjs): TimeOfDay => {
  return {
    hour: time.get('hour'),
    minute: time.get('minute'),
  };
};

export const timeOfDayToDayjs = (time: TimeOfDay): dayjs.Dayjs => {
  return dayjs().set('hour', time.hour).set('minute', time.minute);
};

export const displayArrival = (time: dayjs.Dayjs): string => {
  // TODO - what if it's the next day?
  return time.format('h:mm A');
};
