import React from 'react';
import { useLocalStorage } from './useLocalStorage';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import TimeOfDayField from './TimeOfDayField';
import { DEFAULT_STORAGE, EVConnectorNames, EVConnectorType, TimeOfDay } from './constants';

const Settings: React.FC = () => {
  const [chargeTime, setChargeTime] = useLocalStorage('chargeTime');
  const [chargerTypes, setChargerTypes] = useLocalStorage('chargerTypes');
  const [meals, setMeals] = useLocalStorage('meals');
  const [range, setRange] = useLocalStorage('range');

  const [open, setOpen] = React.useState(false);

  const handleChangeFirstMeal = (value: TimeOfDay | null) => {
    if (value) {
      setMeals([value, meals[1]]);
    }
  };

  const handleChangeSecondMeal = (value: TimeOfDay | null) => {
    if (value) {
      setMeals([meals[0], value]);
    }
  };

  const handleChangeRange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.valueAsNumber > 0) {
      setRange(event.target.valueAsNumber);
    }
  };

  const handleChangeChargeTime: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.valueAsNumber > 0) {
      setChargeTime(event.target.valueAsNumber);
    }
  };

  const handleChargerTypesChanged = (type: EVConnectorType, checked: boolean) => {
    const newChargerTypes = [...chargerTypes];
    if (checked) {
      newChargerTypes.push(type);
    } else {
      newChargerTypes.splice(newChargerTypes.indexOf(type), 1);
    }
    if (newChargerTypes.length > 0) {
      setChargerTypes(newChargerTypes);
    }
  };

  const handleReset = () => {
    setChargerTypes(DEFAULT_STORAGE['chargerTypes']);
    setChargeTime(DEFAULT_STORAGE['chargeTime']);
    setMeals(DEFAULT_STORAGE['meals']);
    setRange(DEFAULT_STORAGE['range']);
  };

  return (
    <>
      <IconButton color="primary" onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{ sx: { maxWidth: '300px', width: '100% ' } }}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} mt={1}>
            <TimeOfDayField label="Meal 1" setValue={handleChangeFirstMeal} value={meals[0]} />
            <TimeOfDayField label="Meal 2" setValue={handleChangeSecondMeal} value={meals[1]} />
            <TextField
              label="Highway range (miles)"
              onChange={handleChangeRange}
              type="number"
              value={range}
            />
            <TextField
              label="Charge time (10%-80% in minutes)"
              onChange={handleChangeChargeTime}
              type="number"
              value={chargeTime}
            />
            <FormGroup>
              {Object.entries(EVConnectorNames).map(([key, value]) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={chargerTypes.includes(key as EVConnectorType)}
                      onChange={(_event, checked) =>
                        handleChargerTypesChanged(key as EVConnectorType, checked)
                      }
                    />
                  }
                  key={key}
                  label={value}
                />
              ))}
            </FormGroup>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Settings;
