import _debounce from 'lodash/debounce';
import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

type Props = {
  label: string;
  location: google.maps.LatLng | null;
  onSelect: (placeId: string | null) => void;
  persistedValue: string | google.maps.LatLng | null;
};

const LocationSearchField: React.FC<Props> = ({ label, location, onSelect, persistedValue }) => {
  const [value, setValue] = React.useState<google.maps.places.AutocompletePrediction | null>(null);
  const [results, setResults] = React.useState<google.maps.places.AutocompletePrediction[]>([]);

  const debouncedSearch = React.useCallback(
    _debounce(async (search: string) => {
      if (search) {
        const autocompleteService = new google.maps.places.AutocompleteService();
        const placeResults = await autocompleteService.getPlacePredictions({
          input: search,
          locationBias: location,
        });
        setResults(placeResults.predictions);
      }
    }, 200),
    [location],
  );

  React.useEffect(() => {
    if (typeof persistedValue !== 'string') {
      setValue(null);
    }
  }, [persistedValue]);

  const handleChange = async (newValue: google.maps.places.AutocompletePrediction | null) => {
    setValue(newValue);
    onSelect(newValue?.place_id || null);
  };

  return (
    <Autocomplete
      filterOptions={(x) => x}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.description}
      options={results}
      onChange={(_event, newValue) => handleChange(newValue)}
      onInputChange={(_event, search) => debouncedSearch(search)}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={value}
    />
  );
};

export default LocationSearchField;
