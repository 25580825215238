import React from 'react';
import { TimeOfDay } from './constants';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { dayjsToTimeOfDay, timeOfDayToDayjs } from './dateUtils';

type Props = {
  label: string;
  setValue: (value: TimeOfDay | null) => void;
  value: TimeOfDay | null;
};

const TimeOfDayField: React.FC<Props> = ({ label, setValue, value }) => {
  const dayjsValue = value && timeOfDayToDayjs(value);

  const handleChange = (time: dayjs.Dayjs | null) => {
    // TODO - is this localized?
    setValue(time?.isValid() ? dayjsToTimeOfDay(time) : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimeField label={label} onChange={handleChange} value={dayjsValue} />
    </LocalizationProvider>
  );
};

export default TimeOfDayField;
