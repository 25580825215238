import dayjs from 'dayjs';
import { TimeOfDay } from './constants';
import { Charger } from './apis/searchAlongRoute';
import { timeOfDayToDayjs } from './dateUtils';

type ChargerAndArrival = {
  arrivalTime: dayjs.Dayjs;
  charger: Charger;
};

export const findStopsForMeal = ({
  chargers,
  chargeTime,
  mealTimes,
  range,
  startTime,
}: {
  chargers: Charger[];
  chargeTime: number;
  mealTimes: TimeOfDay[];
  range: number;
  startTime: TimeOfDay;
}): ChargerAndArrival[] => {
  const stops: ChargerAndArrival[] = [];
  chargers.forEach((charger) => {
    const arrivalTime = getArrivalTime({ chargeTime, charger, range, startTime });
    if (
      arrivalTime &&
      isWithinMealTime({ arrivalTime, mealTimes }) &&
      charger.minutesOffRoute <= 12
    ) {
      stops.push({
        arrivalTime,
        charger,
      });
    }
  });
  return stops;
};

const isWithinMealTime = ({
  arrivalTime,
  mealTimes,
}: {
  arrivalTime: dayjs.Dayjs;
  mealTimes: TimeOfDay[];
}) => {
  const mealWindow = 60; // one hour
  const mealDates = mealTimes.map((meal) =>
    dayjs().set('hour', meal.hour).set('minute', meal.minute),
  );
  return mealDates.some(
    (meal) =>
      arrivalTime.isAfter(meal.clone().subtract(mealWindow, 'minutes')) &&
      arrivalTime.isBefore(meal.clone().add(mealWindow, 'minutes')),
  );
};

const getArrivalTime = ({
  chargeTime,
  charger,
  range,
  startTime,
}: {
  chargeTime: number;
  charger: Charger;
  range: number;
  startTime: TimeOfDay;
}): dayjs.Dayjs => {
  const seventyPercentRange = range * 0.7;
  const distanceInMiles = charger.distanceMeters / 1609;
  const timeSpentCharging = (distanceInMiles / seventyPercentRange) * chargeTime;
  return timeOfDayToDayjs(startTime)
    .add(charger.secondsToCharger, 'seconds')
    .add(timeSpentCharging, 'minutes');
};

type Location = {
  latitude: number;
  longitude: number;
};
export const getDistanceBetweenPoints = (a: Location, b: Location): number => {
  const distance = Math.sqrt(
    Math.pow(b.latitude - a.latitude, 2) + Math.pow(b.longitude - a.longitude, 2),
  );
  return Math.round(distance * 111_111 * 3.281); // Lat/lng to meters to feet
};
