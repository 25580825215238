import { searchNearby, SearchNearbyResponse } from './fetchQueries';
import { Charger } from './searchAlongRoute';

export type Restaurant = {
  displayName: {
    text: string;
  };
  googleMapsLinks: {
    directionsUri: string;
    photosUri: string;
    placeUri: string;
    reviewsUri: string;
    writeAReviewUri: string;
  };
  id: string;
  location: {
    latitude: number;
    longitude: number;
  };
};

export const findRestaurants = async (charger: Charger): Promise<Restaurant[]> => {
  const response: SearchNearbyResponse = await searchNearby({
    body: {
      locationRestriction: {
        circle: {
          center: {
            latitude: charger.location.latitude,
            longitude: charger.location.longitude,
          },
          radius: 300, // 1000 feet
        },
      },
      includedTypes: ['restaurant'],
    },
    fields: ['places.id', 'places.location', 'places.displayName', 'places.googleMapsLinks'],
  });
  return response.places;
};
